import React from 'react';
import '../index.css';

const MenuSpecialties = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Chef's Specialties</div>
      <div className='menu--section-desc'>Served with choice of salad, garlic mayo, sweet chilli & kebab sauce</div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken or Lamb Tikka Masala <span className='red'>🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with almonds, coconut, nuts & sultanas in a mild sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Jalfrezi or Lamb Jalfrezi <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with fresh green chillies, peppers & coriander with chef special sauce to give a rich flavour.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Korahi or lamb Korahi <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>A traditional dish cooked with fresh onions, garlic, peppers & coriander to give a medium flavour.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Akkbori Chicken/Lamb <span className='red'>🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with pistachio, almonds & coconut in a mild sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Hot & Spicy Chicken <span className='red'>🌶️🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with chopped green chillies, garlic, ginger and tomatoes.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Indian Special Chicken <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£10.50</div>
        <div className='menu--item-description'>Specially cooked with chicken tikka, lamb tikka & sheek kebab with cheese</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Tajmahal Chicken <span className='red'>🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with fresh spring chicken with fresh cream, herbs, spice & touch of almond & coconut.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Sweet and Chilli Chicken <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with coconut cream, pure butter, single cream & Bangladeshi pure honey sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>King Prawn Golap <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£12.00</div>
        <div className='menu--item-description'>A special tandoori dish (cooked in hot sweet chillies, garlic, ginger & tomatoes).</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Sweet Honey Chicken</div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with coconut cream, pure butter, single cream & Bangladeshi pure honey sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken or Lamb Tikka Razala <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with onions, peppers, tomatoes, fresh green chillies & our chef special spices, a spicy rich sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Tandoori Mix Korahi <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£10.00</div>
        <div className='menu--item-description'>Marinated chicken, lamb, tandoori chicken, pepper, onion well tandoori special spice in thick sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Butter Chicken <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with butter cream, almonds & sultanas, sweet tasting bhuna style.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Peri-Peri Chicken <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Thinly sliced chicken, cooked in a homemade peri-peri rich sauce with onion pepper,
          chopped green chilli & topping with fresh coriander.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken or Lamb Tikka Passanda</div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked in a very mild & creamy sauce with yogurt.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken or Lamb Garlic Chilli Bhuna <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with onions, garlic, tomatoes & chillies which provides a hot flavour.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken or Lamb Tikka Majadar <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with fresh green chilli, pepper, and yogurt in a fairly hot sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Peshwari Chicken <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Chicken tikka, cooked with fried onion, ground almond & fresh cream.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Royal Korma</div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Baby chicken tikka, cooked with almond, coconut cream sauce and chefs own recipe.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Balti Supreme <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£10.50</div>
        <div className='menu--item-description'>Chicken, lamb, prawn, mushroom, onion, green pepper with well balti spice in thick sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Jall-Jall Chicken or Lamb <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Marinated in tamarind and a sauce consisting of coriander, onions, green peppers & sultanas.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chef's Special Biryani <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£10.50</div>
        <div className='menu--item-description'>Prepared with chicken, lamb, prawn, mushroom, basmati rice, herbs, exotic Indian spices topped with egg & served with vegetable curry.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken or Lamb Kabuli <span className='red'>🌶️</span></div>
        <div className='menu--item-price'>£9.50</div>
        <div className='menu--item-description'>Chunks of chicken tikka cooked in garlic, tomato puree, yogurt, simmered in cream, sugar, and pepper.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken or Lamb Tikka Makhani <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Chicken roasted in fresh cream, cooked in coconut and chutney.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Railway Lamb <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£10.00</div>
        <div className='menu--item-description'>Spring lamb cooked with fresh coriander, garlic and green chilli with chef's special spice in a thick sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Rajasthani Chicken <span className='red'>🌶️</span></div>
        <div className='menu--item-price'>£9.50</div>
        <div className='menu--item-description'>Sliced chicken marinated in spice and herbs. Cooked in medium spice & a touch of coconut and mango.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Punjabi Masala <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.50</div>
        <div className='menu--item-description'>Cooked with garlic, green peppers, mixed herbs and coriander with the Chef's special sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Murghi Masala <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>£10.00</div>
        <div className='menu--item-description'>Chicken off the bone & keema (minced lamb meat) cooked in exotic special spices.</div>
      </div>

    </div>
  );
};

export default MenuSpecialties