import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import Homepage from './pages/Homepage'
import Footer from './components/Footer';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';
import './App.css';

import Menu from './pages/MenuPage';

library.add(faFacebookF, faTwitter, faInstagram);

function App() {
  return (
    <BrowserRouter>
    <div>
      <Landing />
      <Navbar />
      <Routes>
          <Route path='/' element={<Homepage />}>
          </Route>
          <Route path='/menu' element={<Menu />}>
          </Route>
      </Routes>
      <Footer />

    </div>
    </BrowserRouter>
  );
};

export default App;
