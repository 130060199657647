import React from 'react';
import '../index.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const openMenu = () => {
    document.querySelector('.menu').classList.add('open');
  };

  const closeMenu = () => {
    document.querySelector('.menu').classList.remove('open');
  };

  return (
    <nav>
      <div className="nav__row row">
        <Link to="/" className="logo__wrapper navy">
          <img src="" alt="" className="" />
          Dungannon Spice
        </Link>
        <ul className="nav__links">
          <li><Link to="/menu"  ><button className="nav__link--signup"><a href="#">Menu</a></button></Link></li>
        </ul>
        <figure className="menu__button--open" onClick={openMenu}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="light___StyledMenuIcon-lj69nl-15 cPytOe">
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </figure>
        <div className="menu">
          <figure className="menu__button--close" onClick={closeMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="light___StyledCloseIcon-lj69nl-14 fASujC">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </figure>
          <div className="menu__links">
            <a href="#" className="menu__link link__hover-effect">About</a>
            <a href="#" className="menu__link link__hover-effect">Menu</a>
            <button className="menu__link menu__link--signup"><a href="#">Order</a></button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
