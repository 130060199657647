import React from 'react';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
const Footer = () => {
    return (
        <footer>
            <div className="footer__container">
                <div className="footer__row">
                    <div className="footer__bottom">
                        <a className="footer__icon--wrapper" href="">
                        </a>
                        <p className="footer__copyright">
                            © 2024 Indian Spice Dungannon Ltd.
                            <br />
                            All Rights Reserved.
                        </p>
                        <div className="footer__social--links">
                            <a href="https://facebook.com/">
                                <figure className="footer__social--link ">
                                    <FontAwesomeIcon icon={faFacebookF} className="footer__social--link--img" />
                                </figure>
                                </a>    
                            <a href='https://twitter.com'>
                                <figure className="footer__social--link ">
                                    <FontAwesomeIcon icon={faTwitter} className="footer__social--link--img" />
                                </figure>
                                </a>
                                <a href='https://instagram.com'>
                                <figure className="footer__social--link">
                                    <FontAwesomeIcon icon={faInstagram} className="footer__social--link--img" />
                                </figure>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;

