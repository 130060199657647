import React from 'react';
import '../index.css';

const MenuStarters = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Starters</div>
      <div className='menu--section-desc'>All starters are served with salad and mint sauce</div>
      <div className='menu--box'>
        <div className='menu--item'>Onion Bhaji</div>
        <div className='menu--item-price'>£3.00</div>
        <div className='menu--item-description'>Sliced onions mixed with gram flour, herbs, spices and deep fried</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Pakora</div>
        <div className='menu--item-price'>£3.50</div>
        <div className='menu--item-description'>Chicken fillet spiced, seasoned and deep fried in our own batter</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Vegetable or Meat Samosa</div>
        <div className='menu--item-price'>£3.50</div>
        <div className='menu--item-description'>Three pieces per portion</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Tandoori Mix Starters</div>
        <div className='menu--item-price'>£5.00</div>
        <div className='menu--item-description'>Chicken tikka, lamb tikka, tandoori chicken</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Tandoori Chicken on the Bone</div>
        <div className='menu--item-price'>£3.50</div>
        <div className='menu--item-description'>Grilled and marinated in fresh spices</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Tikka or Lamb Tikka</div>
        <div className='menu--item-price'>£3.50</div>
        <div className='menu--item-description'>Marinated in spices and cooked in a tandoor</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Sheek or Shami Kebab</div>
        <div className='menu--item-price'>£3.50</div>
        <div className='menu--item-description'>Minced lamb with herbs and spices, skewered and grilled</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Veg Pakora</div>
        <div className='menu--item-price'>£3.00</div>
        <div className='menu--item-description'>Mixed vegetables seasoned and deep fried in batter</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Prawn Puree</div>
        <div className='menu--item-price'>£3.50</div>
        <div className='menu--item-description'>Spiced prawns served on a deep-fried Indian bread</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>King Prawn Puree</div>
        <div className='menu--item-price'>£5.00</div>
        <div className='menu--item-description'>Spiced king prawns served on a deep-fried Indian bread</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Chat or Lamb Chat or Aloo Chat</div>
        <div className='menu--item-price'>£3.50</div>
        <div className='menu--item-description'>Spiced and tangy, served with a salad</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Veg Mixed Starter</div>
        <div className='menu--item-price'>£4.00</div>
        <div className='menu--item-description'>Onion Bhaji, Veg Pakora & Veg Samosa</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Indian Spice Starter</div>
        <div className='menu--item-price'>£5.00</div>
        <div className='menu--item-description'>Onion Bhaji, Chicken Pakora, Chicken Tikka, Sheek Kebab</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Salti Chilli Chicken</div>
        <div className='menu--item-price'>£5.00</div>
        <div className='menu--item-description'>Spicy and tangy chili chicken stir-fry</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Raita</div>
        <div className='menu--item-price'>£2.00</div>
        <div className='menu--item-description'>Choice of Onion, Cucumber, or Mix Raitha</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Papadom</div>
        <div className='menu--item-price'>£0.60</div>
        <div className='menu--item-description'>Plain Papadom or Spicy Papadom</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chutney</div>
        <div className='menu--item-price'>£0.50</div>
        <div className='menu--item-description'>Mango Chutney, Mint Sauce, Onion Salad, or Mixed Pickle</div>
      </div>
    </div>
    );
  };

export default MenuStarters