import React from 'react';
import '../index.css';

const MenuJuniorMeals = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Junior Meals</div>
      <div className='menu--section-desc'>Served with choice of salad, garlic mayo, sweet chilli & kebab sauce</div>
      <div className='menu--box'>
  <div className='menu--item'>Steak Burger</div>
  <div className='menu--item-price'>£3.50</div>
  <div className='menu--item-description'>Juicy steak burger served in a soft bun.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Cheese Burger</div>
  <div className='menu--item-price'>£3.50</div>
  <div className='menu--item-description'>Classic cheese burger topped with onions & red sauce.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Chicken Fillet Burger</div>
  <div className='menu--item-price'>£4.50</div>
  <div className='menu--item-description'>Crispy chicken fillet burger with lettuce & mayo.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Chicken Goujons & Chips</div>
  <div className='menu--item-price'>£6.00</div>
  <div className='menu--item-description'>Tender chicken goujons served with crispy chips.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Chicken Nuggets & Chips (6 Pieces)</div>
  <div className='menu--item-price'>£4.00</div>
  <div className='menu--item-description'>Six pieces of chicken nuggets with a side of chips.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Chicken Nuggets & Chips (8 Pieces)</div>
  <div className='menu--item-price'>£5.00</div>
  <div className='menu--item-description'>Eight pieces of chicken nuggets served with chips.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Rice, Chips & Curry Sauce</div>
  <div className='menu--item-price'>£4.50</div>
  <div className='menu--item-description'>A hearty serving of rice, chips, and curry sauce.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Rice, Chips & Gravy Sauce</div>
  <div className='menu--item-price'>£4.50</div>
  <div className='menu--item-description'>Combination of rice, chips, and rich gravy sauce.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Chicken Omelette & Chips</div>
  <div className='menu--item-price'>£6.00</div>
  <div className='menu--item-description'>Fluffy chicken omelette served with a side of chips.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Mushroom Omelette & Chips</div>
  <div className='menu--item-price'>£6.00</div>
  <div className='menu--item-description'>Omelette filled with mushrooms, served with chips.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Chicken Fried Rice</div>
  <div className='menu--item-price'>£7.00</div>
  <div className='menu--item-description'>Fried rice tossed with tender pieces of chicken.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Lamb Fried Rice</div>
  <div className='menu--item-price'>£8.00</div>
  <div className='menu--item-description'>Flavorful fried rice mixed with succulent lamb pieces.</div>
</div>

    </div>
  );
};

export default MenuJuniorMeals