import React from 'react';
import '../index.css';

const Landing = () => {
  return (
    <section id="landing">
    </section>
  );
};

export default Landing;
