import React from 'react';
import '../index.css';

const MenuKebabs = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Kebabs</div>
      <div className='menu--section-desc'>Served with choice of salad, garlic mayo, sweet chilli & kebab sauce</div>

      <div className='menu--box'>
        <div className='menu--item'>Doner Kebab</div>
        <div className='menu--item-price'>£5.00</div>
        <div className='menu--item-description'>With pitta bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Doner King Kebab</div>
        <div className='menu--item-price'>£6.00</div>
        <div className='menu--item-description'>With naan bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chips & Doner King Kebab</div>
        <div className='menu--item-price'>£7.00</div>
        <div className='menu--item-description'>With pitta bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Tikka Kebab</div>
        <div className='menu--item-price'>£5.50</div>
        <div className='menu--item-description'>With pitta bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Tikka King Kebab</div>
        <div className='menu--item-price'>£6.50</div>
        <div className='menu--item-description'>With naan bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Mixed Kebab</div>
        <div className='menu--item-price'>£7.00</div>
        <div className='menu--item-description'>Chicken tikka & doner meat with naan bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Shaslick Kebab</div>
        <div className='menu--item-price'>£6.50</div>
        <div className='menu--item-description'>Chicken tikka with fried cubed onion with naan bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Vegetable Kebab</div>
        <div className='menu--item-price'>£5.00</div>
        <div className='menu--item-description'>With pitta bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Vegetable King Kebab</div>
        <div className='menu--item-price'>£5.50</div>
        <div className='menu--item-description'>With naan bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Lamb and Chicken Tikka King Kebab</div>
        <div className='menu--item-price'>£7.00</div>
        <div className='menu--item-description'>With naan bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Indian Special Kebab</div>
        <div className='menu--item-price'>£7.50</div>
        <div className='menu--item-description'>Chicken tikka, doner meat, onion bhaji & chicken pakora fried onion, pepper.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Pakora Kebab</div>
        <div className='menu--item-price'>£6.00</div>
        <div className='menu--item-description'>Chicken fillet spiced, seasoned & deep fried in our own batter.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Pakora Mixed Kebab</div>
        <div className='menu--item-price'>£7.00</div>
        <div className='menu--item-description'>Mixed kebab chicken tikka & chicken pakora with naan bread.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Doner Wrap</div>
        <div className='menu--item-price'>£5.50</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Wrap</div>
        <div className='menu--item-price'>£6.00</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Doner Kebab Box</div>
        <div className='menu--item-price'>Small £3.00 • Regular £4.00 • Large £5.50</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Doner Kebab with Chips</div>
        <div className='menu--item-price'>Regular £5.00 • Large £6.00</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Tikka with Chips</div>
        <div className='menu--item-price'>Regular £5.50 • Large £6.50</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Mixed Kebab with Chips</div>
        <div className='menu--item-price'>Regular £6.00 • Large £7.00</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Vegetable Kebab with Chips</div>
        <div className='menu--item-price'>Regular £5.00 • Large £6.00</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Pakora with Chips</div>
        <div className='menu--item-price'>Regular £5.50 • Large £6.50</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Pakora and Tikka with Chips</div>
        <div className='menu--item-price'>Regular £6.00 • Large £7.00</div>
      </div>
    </div>
  );
};

export default MenuKebabs