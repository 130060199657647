import React from 'react';
import '../index.css';

const Quality = () => {
  return (
    <section id="quality">
      <div className="quality__container">
        <div className="quality__row">
          <div className="quality__left-column">
            <h6 className="quality__title white">OUR QUALITY</h6>
            <h1 className="quality__subtitle white">Authentic Indian Cuisine</h1>
            <p className="quality__para white">Our cuisine has been hand-picked to celebrate the extraordinary combination of flavours, freshly ground spices, marinades and complex tastes.</p>
          </div>
          <div className="menu__button">
            <button className="our-menu--button"><a href="#">Our Menu</a></button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quality;
