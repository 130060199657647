import React from 'react';
import '../index.css';
import Image from '../assets/image3.webp'

const AboutUs = () => {
  return (
    <section id="about-us">
      <div className="about-us__container">
        <div className="about-us__row">
        <figure class="about-us__img-wrapper">
            <img src={Image} class="about-us__img" />
          </figure>
          <div className="about-us__right-column">
            <div className="about-us__heading">
              <h6 className="about-us__title red">WHO WE ARE</h6>
              <h1 className="about-us__subtitle red">The finest Indian cuisine in the very heart of Dungannon</h1>
              <p className="about-us__para red">Dungannon Spice offers a delightful selection of Indian dishes and fast food. We are renowned for our excellent service and fresh food. Whether you're picking up takeout or opting for delivery, Indian Spice is a top choice for tasty and wholesome Indian cuisine in Dungannon.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
