import React from 'react';
import '../index.css';

const MenuSundries = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Sundries</div>

      <div className='menu--box'>
        <div className='menu--item'>Boiled Rice</div>
        <div className='menu--item-price'>£2.50</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Pilau Rice</div>
        <div className='menu--item-price'>£2.50</div>
      </div>
      
      <div className='menu--box'>
        <div className='menu--item'>Egg or Vegetable Fried Rice</div>
        <div className='menu--item-price'>£3.00</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Garlic or Onion Fried Rice</div>
        <div className='menu--item-price'>£3.00</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Special Pilau Rice</div>
        <div className='menu--item-price'>£3.00</div>
        <div className='menu--item-description'>With peas, onions and eggs</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Keema or Mushroom Pilau Rice</div>
        <div className='menu--item-price'>£3.00</div>
      </div>
    </div>
  );
};

export default MenuSundries