import Menu from "../components/Menu";

const MenuPage = () => {
    return (
      <div>
        <Menu />
      </div>
    );
  };
  
  export default MenuPage;