import React from 'react';
import '../index.css';
import Starters from './MenuStarters';
import MenuTandooriClayOven from './MenuTandooriClayOven';
import Kebabs from './MenuKebabs';
import Vegetarian from './MenuVegetarian';
import TraditionalDishes from './MenuTraditionalDishes';
import Specialties from './MenuSpecialties';
import Breads from './MenuBreads';
import Sundries from './MenuSundries';
import Chips from './MenuChips';
import JuniorMeals from './MenuJuniorMeals';
import SetMeals from './MenuSetMeals';
import FamilyMeals from './MenuFamilyMeals';
import MenuCarousel from './MenuCarousel';

const Menu = () => {
    return (
        <div>
            <MenuCarousel />
            <Starters />
            <MenuTandooriClayOven />
            <Kebabs />
            <Vegetarian />
            <TraditionalDishes />
            <Specialties />
            <Sundries />
            <Breads />
            <Chips />
            <JuniorMeals />
            <SetMeals />
            <FamilyMeals />
        </div>
    );
  };

export default Menu