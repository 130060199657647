import React from 'react';
import '../index.css';

const MenuBreads = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Breads</div>

      <div className='menu--box'>
        <div className='menu--item'>Plain Naan</div>
        <div className='menu--item-price'>£2.50</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Tandoori Roti</div>
        <div className='menu--item-price'>£3.00</div>
        <div className='menu--item-description'>With garlic & coriander.</div>

      </div>

      <div className='menu--box'>
        <div className='menu--item'>Peshwari Naan</div>
        <div className='menu--item-price'>£3.00</div>
        <div className='menu--item-description'>Our own sweet recipe.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Keema Naan</div>
        <div className='menu--item-price'>£3.00</div>
        <div className='menu--item-description'>With minced meat.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Onion Naan</div>
        <div className='menu--item-price'>£3.00</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Vegetable and Chilli Naan</div>
        <div className='menu--item-price'>£3.00</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Cheese Naan</div>
        <div className='menu--item-price'>£3.00</div>
      </div>

    </div>
  );
};

export default MenuBreads