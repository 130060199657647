import React from 'react';
import '../index.css';

const MenuSetMeals = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Set Meals</div>
      <div className='menu--section-desc'>Served with choice of salad, garlic mayo, sweet chilli & kebab sauce</div>
      <div className='menu--box'>
  <div className='menu--item'>Doner Kebab, Chips & Can of Drink</div>
  <div className='menu--item-price'>£6.00</div>
  <div className='menu--item-description'>Delicious doner kebab served with chips and a can of your favorite drink.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Cheese Burger, Chips & Can of Drink</div>
  <div className='menu--item-price'>£6.00</div>
  <div className='menu--item-description'>Classic cheese burger with chips and a can of your favorite drink.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Chicken Fillet Burger, Chips & Can of Drink</div>
  <div className='menu--item-price'>£6.50</div>
  <div className='menu--item-description'>Crispy chicken fillet burger served with chips and a can of your favorite drink.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Chicken Goujons, Chips & Can of Drink</div>
  <div className='menu--item-price'>£6.50</div>
  <div className='menu--item-description'>Tender chicken goujons with chips and a can of your favorite drink.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Chicken Nuggets (8 Pieces), Chips & Can of Drink</div>
  <div className='menu--item-price'>£5.50</div>
  <div className='menu--item-description'>Eight pieces of chicken nuggets, served with chips and a can of your favorite drink.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Onion Rings, Chips & Can of Drink</div>
  <div className='menu--item-price'>£5.00</div>
  <div className='menu--item-description'>Crispy onion rings served with chips and a can of your choice of drink.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>Fish, Chips & Can of Drink</div>
  <div className='menu--item-price'>£7.00</div>
  <div className='menu--item-description'>Classic fish and chips meal, served with a can of your favorite drink.</div>
</div>

<div className='menu--box'>
  <div className='menu--item'>2 Pcs Chicken Drum Stick, Chips & Can of Drink</div>
  <div className='menu--item-price'>£5.00</div>
  <div className='menu--item-description'>Two pieces of chicken drumsticks served with chips and a can of drink.</div>
</div>

    </div>
  );
};

export default MenuSetMeals