import React from 'react';
import '../index.css';

const MenuFamilyMeals = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Family Meals</div>
      <div className='menu--section-desc'>Meals designed to satisfy any family. Dishes can be changed.</div>

      <div className='menu--box'>
        <div className='menu--item'>Family Meal for 1</div>
        <div className='menu--item-price'>£12.90</div>
        <div className='menu--item-description'>Starters: Onion Bhaji, 2 Poppadom & Chutney • Main: Chicken Dupiaza, Bombay Potato &
          Pilau Rice</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Family Meal for 2</div>
        <div className='menu--item-price'>£27.90</div>
        <div className='menu--item-description'>Starters: Veg Mixed Starter, Chicken Tikka,
          2 Poppadoms & Chutney • Main: Chicken Korma, Lamb Bhuna, Sag Aloo,
          Boiled Rice, Pilau Rice, Plain Naan</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Family Meal for 4</div>
        <div className='menu--item-price'>£47.90</div>
        <div className='menu--item-description'>Starters: Tandoori Chicken, Chicken Tikka,
          Vegetable Samosa, Onion Bhaji,
          4 Poppadoms & Chutney • Main: Chicken Bhuna, Chicken Tikka Massala,
          Lamb Balti, Prawn Dupiaza, Bombay Aloo,
          Mix Vegetable Curry, 2 Boiled Rice,
          2 Pilau Rice, Peshwari Naan & Chips</div>
      </div>

    </div>
  );
};

export default MenuFamilyMeals