import React from 'react';
import '../index.css';

const MenuVegetarian = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Vegetarian</div>
      <div className='menu--section-desc'>Served with choice of salad, garlic mayo, sweet chilli & kebab sauce</div>
      <div className='menu--box'>
        <div className='menu--item'>Veg Curry</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Mixed vegetables cooked in a blend of aromatic spices.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Dry Mixed Veg</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>A medley of vegetables sautéed with spices for a dry preparation.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Sag Aloo</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Spinach and potato cooked with herbs and spices.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Bombay Aloo</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Spiced potato dish with a touch of turmeric and coriander.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Aloo Peas</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Potato and peas simmered in a rich, spiced gravy.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Mushroom Bhaji</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Sautéed mushrooms in a blend of spices.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Aloo Gobi</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Potato and cauliflower cooked with spices.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Tarka Dall</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Lentils cooked with fried garlic and spices.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chana Massala</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Chickpeas simmered in a flavorful, spiced gravy.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Sag Bhaji</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Spinach cooked with spices for a delicious and healthy dish.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Cauliflower Bhaji</div>
        <div className='menu--item-price'>Side Dish £3.50 • Main Course £7.00</div>
        <div className='menu--item-description'>Cauliflower florets cooked in a spiced, dry preparation.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Any Veg Traditional Dish</div>
        <div className='menu--item-price'>£7.00</div>
        <div className='menu--item-description'>Choose any traditional vegetable dish from our selection.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Any Veg Chefs Specialist Dish</div>
        <div className='menu--item-price'>£8.50</div>
        <div className='menu--item-description'>Special dishes crafted by our chef using the freshest vegetables.</div>
      </div>

    </div>
  );
};

export default MenuVegetarian