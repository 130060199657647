import AboutUs from "../components/AboutUs"
import Welcome from "../components/Welcome"
import Quality from "../components/Quality"
import FindUs from "../components/FindUs"

const Homepage = () => {
    return (
        <>
        <Welcome />
        <AboutUs />
        <Quality />
        <FindUs />
        </>
    )
}

export default Homepage