import React from 'react';
import '../index.css';

const MenuTraditionalDishes = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Traditional Dishes</div>
      <div className='menu--section-desc'>Our traditional Indian dishes, infused with flavour. Includes boiled or pilau rice or chips.</div>
      
      <div className='menu--box'>
        <div className='menu--item'>Korma <span className='red'>🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Mild dish consisting of coconut, dairy cream to give its own distinctive flavour & taste. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Curry <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Beautiful combination of wholesome onion gravy & flavoured with authentic spice. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Madras <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Beautiful combination of wholesome onion gravy & flavoured with authentic spice. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Vindaloo or Phal <span className='red'>🌶️🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £8.00 • Chicken Tikka or Lamb £9.00 • Lamb Tikka or Prawn £9.00 • King Prawn £11.50</div>
        <div className='menu--item-description'>Beautiful combination of wholesome onion gravy & flavoured with authentic spice. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Mushroom Dupiaza <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £8.00 • Chicken Tikka or Lamb £9.00 • Lamb Tikka or Prawn £9.00 • King Prawn £11.50</div>
        <div className='menu--item-description'>Medium, thick sauce with cubed onions, green peppers & mushroom. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Shatkora <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked with Bangladeshi vegetable and chef own recipe. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Bhuna <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked in a thick sauce with onion & tomatoes. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Rojan Josh <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked with fresh herbs & spices topped with tomatoes & green peppers . </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Mr Naga-Jal <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £8.00 • Chicken Tikka or Lamb £9.00 • Lamb Tikka or Prawn £9.00 • King Prawn £11.50</div>
        <div className='menu--item-description'>Bangladeshi mr naga chilli sauce with cubed onion & pepper produce a warm flavour. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Dupiaza <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Medium, thick sauce with cubed onions & green peppers. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Dansak <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked with lentils. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Pathia <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked in a sweet, sour & fairly hot sauce. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Kashmiri <span className='red'>🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>A mild dish cooked with mix fruit, single cream & coconut cream. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Spinach <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked with spinach & fresh herbs. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Garlic Bhuna <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked with fresh fried garlic, herbs, onions & thick sauce. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Saly <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked with onion, capsicum, herbs & spice in highly flavour with rich sauce topped with
        fried shredded potato. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Delhi Spice <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked in a specially blended onion pepper mixed pickles sauce. </div>
      </div>
            
      <div className='menu--box'>
        <div className='menu--item'>Khusboo <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked with onion, garlic, green chilli & mint, & spices. create an aromatic flavour. </div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Balti <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £8.00 • Chicken Tikka or Lamb £9.00 • Lamb Tikka or Prawn £9.00 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked with onion, green pepper & tomatoes in a special balti sauce, consistency is
        much stronger & richer than any basic curry. </div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chom-Chom <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £7.50 • Chicken Tikka or Lamb £8.50 • Lamb Tikka or Prawn £8.50 • King Prawn £11.50</div>
        <div className='menu--item-description'>Cooked with onion, garlic, green chilli & mint, & spices. create an aromatic flavour. </div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Xacuti <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £8.00 • Chicken Tikka or Lamb £9.00 • Lamb Tikka or Prawn £9.00 • King Prawn £11.50</div>
        <div className='menu--item-description'>A South Indian curry with a highly flavoured spice. Fairly hot prepared with coconut makeing in a traditional good speciality. </div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Biryani <span className='red'>🌶️🌶️</span></div>
        <div className='menu--item-price'>Breast Chicken £8.00 • Chicken Tikka or Lamb £9.00 • Lamb Tikka or Prawn £9.00 • King Prawn £11.50</div>
        <div className='menu--item-description'>Prepared with basmati rice, herbs, exotic indian spices & served with vegetable curry. </div>
      </div>

    </div>
  );
};

export default MenuTraditionalDishes