import React from 'react';
import '../index.css';

const Welcome = () => {
  return (
    <section id="welcome">
      <div className="welcome__container">
        <div className="welcome__row">
          <div className="welcome__heading">
            <h6 className="welcome__title white">WELCOME TO</h6>
            <h1 className="welcome__subtitle white">Dungannon Spice</h1>
            <p className="welcome__para white">Taste the Tradition — Authentic Indian cuisine crafted with love in the heart of Dungannon.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
