import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import '../index.css';

const containerStyle = {
    width: '640px',
    height: '400px',
  };
  
  const center = {
    lat: 54.50377,
    lng: -6.768795
  };
  
  function MyMap() {
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyDeuKTdpmIzgs8TGbWwF3tFk5IvrZc1gyw"
    })
  
    const [map, setMap] = React.useState(null)
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])
  
    return isLoaded ? (
        <GoogleMap
          mapContainerClassName="find-us__map"
          mapContainerStyle={containerStyle}
          center={center}
          zoom={20}
        //   onLoad={onLoad}
        //   onUnmount={onUnmount}
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>
    ) : <></>
  
  }

  export default React.memo(MyMap);