import React from 'react';
import '../index.css';

const MenuTandooriClayOven = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Tandoori Clay Oven</div>
      <div className='menu--section-desc'>Medium sizzling tandoori grilled dishes marinated in fresh spices, herbs &
yoghurt. cooked on skewers & garnished with fried onions, peppers &
fresh coriander. Vegetable curry, rice, salad & mint sauce included</div>
      <div className='menu--box'>
        <div className='menu--item'>Chicken Tikka or Lamb Tikka</div>
        <div className='menu--item-price'>£8.50</div>
        <div className='menu--item-description'>Tender pieces of chicken or lamb marinated in yogurt and spices, cooked in a tandoor until perfectly grilled.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Tandoori Chicken on the Bone</div>
        <div className='menu--item-price'>£8.50</div>
        <div className='menu--item-description'>Juicy chicken pieces marinated in a blend of yogurt and spices, then grilled on the bone in a traditional tandoor.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Tandoori Mix Grill</div>
        <div className='menu--item-price'>£9.50</div>
        <div className='menu--item-description'>A delightful assortment of tandoori chicken, chicken tikka, lamb tikka, and sheek kebab, served with salad and mint sauce.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Chicken Shashlick</div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Succulent chicken pieces skewered with onions, tomatoes, and green peppers, marinated and cooked in a tandoor.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Lamb Shashlick</div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Marinated lamb pieces skewered with onions, tomatoes, and green peppers, then grilled in a tandoor to perfection.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Mix Shashlick</div>
        <div className='menu--item-price'>£9.50</div>
        <div className='menu--item-description'>A combination of chicken, lamb, and prawns, skewered with vegetables and grilled in a tandoor.</div>
      </div>

      <div className='menu--box'>
        <div className='menu--item'>Tandoori King Prawn Shashlick</div>
        <div className='menu--item-price'>£12.00</div>
        <div className='menu--item-description'>King prawns marinated in spices, skewered with onions, tomatoes, and green peppers, then grilled in a tandoor.</div>
      </div>

    </div>
  );
};

export default MenuTandooriClayOven