import React from 'react';
import '../index.css';

const MenuChips = () => {
  return (
    <div className='menu__container'>
      <div className='menu--section-name'>Chips</div>
      <div className='menu--box'>
        <div className='menu--item'>Medium Chips</div>
        <div className='menu--item-price'>£2.50</div>
      </div>
      <div className='menu--box'>
        <div className='menu--item'>Large Chips</div>
        <div className='menu--item-price'>£3.00</div>
      </div>
      <div className='menu--box'>
        <div className='menu--item'>Chilli or Garlic Chips</div>
        <div className='menu--item-price'>£3.50</div>
      </div>
      <div className='menu--box'>
        <div className='menu--item'>Cheesy Chips</div>
        <div className='menu--item-price'>£3.50</div>
      </div>
      <div className='menu--box'>
        <div className='menu--item'>Curry or Gravy Chips</div>
        <div className='menu--item-price'>£4.50</div>
      </div>
      <div className='menu--box'>
        <div className='menu--item'>Korma or Masala Chips</div>
        <div className='menu--item-price'>£5.00</div>
      </div>
    </div>
  );
};

export default MenuChips